.roundCorner {
    border-radius: 5px;
    border: none;
}

label {
    display: block;
    margin-bottom: 5px;
}

input {
    width: 100%;
    height: 2em;
    border: 0.1em solid #94866d;
}

textarea {
    width: 100%;
    height: 12em;
    border: 0.1em solid #94866d;
    resize: vertical;
}